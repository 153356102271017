import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Segmented,
  Space,
  notification,
  Divider,
  Checkbox,
  Modal,
} from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState } from "react";
import $axios from "../../services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CryptoJS from "crypto-js";
import {
  faShuffle,
  faPlus,
  faArrowAltCircleRight,
  faMinus,
  faRoad,
  faPlane,
  faUser,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import TypeOfCarJson from "../../data-test/type-of-car.json";
import PriceJson from "../../data-test/price.json";
import Autocomplete from "react-google-autocomplete";
import _, { add } from "lodash";

// https://maps.googleapis.com/maps/api/place/autocomplete/json?input='.$_GET['keyword'].'&language=vi&components=country:vn&location=21.0285,105.8542&types=geocode|establishment&radius=50000&key='.GOOGLE_DISTANCE_API_KEY

const generateSignature = (url, secretKey) => {
  const params = new URLSearchParams(url.split("?")[1]);
  const sortedParams = Array.from(params.entries())
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => value || ""); // Chỉ lấy giá trị, xử lý giá trị rỗng

  sortedParams.push(secretKey);

  // Tạo plaintext bằng cách nối các tham số
  const plaintext = sortedParams.join("|");

  // Tính toán chữ ký SHA-256
  const signature = CryptoJS.SHA256(plaintext).toString(CryptoJS.enc.Hex);

  return signature;
};

const Booking = ({ setLoading, loading }) => {
  const [typeTrip, setTypeTrip] = useState("plane");
  const [totalStopAddress, setTotalStopAddress] = useState(0);
  const [voucherValue, setVoucherValue] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherTxt, setVoucherTxt] = useState("");
  const [typesCar, setTypesCar] = useState([]);
  const [prices, setPrices] = useState(0);
  const [priceSelected, setPriceSelected] = useState(0);
  const [addStart, setAddStart] = useState("");
  const [addPrice, setAddPrice] = useState(0);
  const [addEnd, setAddEnd] = useState(
    typeTrip === "plane" ? "Sân bay Nội bài" : ""
  );
  const [addStop1, setAddStop1] = useState("");
  const [addStop2, setAddStop2] = useState("");
  const [carSelected, setCarSelected] = useState("");
  const [errors, setErrors] = useState({
    addStart: false,
    addEnd: false,
  });
  const [isModalHasPriceOpen, setIsModalHasPriceOpen] = useState(false);
  const [isModalNoPriceOpen, setIsModalNoPriceOpen] = useState(false);
  const [form] = Form.useForm();
  const [formContact] = Form.useForm();
  const [disabledField, setDisabledField] = useState("addEnd");

  useEffect(() => {
    getTypesCar();
    getAddPrice();
  }, []);

  const applyVoucher = async (e) => {
    if (e) {
      setLoading(true);
      try {
        const res = await $axios.$get(`/voucher/getVoucher?voucherCode=${e}`);
        if (res.data.data.status === 1) {
          setVoucherValue(res.data.data.amount);
          setVoucherCode(e);
          setVoucherTxt(
            `<div class="text-[14px] ">Áp dụng mã giảm giá thành công! <span class="text-green-600 text-[16px]">
      ${res.data.data.amount.toLocaleString("vi-VN")} đ</span></div>`
          );
        } else {
          setVoucherTxt(
            `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
          );
          setVoucherValue(0);
          setVoucherCode("");
        }
      } catch (error) {
        setVoucherTxt(
          `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
        );
        setVoucherValue(0);
        setVoucherCode("");
      } finally {
        setLoading(false);
      }
    } else {
      setVoucherTxt(
        `<div class="text-[14px] text-red-600">Mã giảm giá không hợp lệ! Vui lòng thử lại</div>`
      );
      setVoucherValue(0);
      setVoucherCode("");
    }
  };

  const handleFinishContact = async (values) => {
    setLoading(true);
    const pickupTime = `${dayjs(form.getFieldValue("departDate")).format(
      "YYYY-MM-DD HH:mm"
    )}`;
    const body = {
      StartPoint: addStart,
      EndPoint: addEnd,
      Roundtrip: form.getFieldValue("roundtrip") ? 1 : 0,
      Vat: form.getFieldValue("VAT") ? 1 : 0,
      YourName: values.fullName,
      YourPhone: values.phone,
      Ngaydatxe: pickupTime,
      LoaiXe: carSelected,
      price: priceSelected,
    };

    try {
      const res = await $axios.$post(`/taxi/sendBooking`, body);
      const bodyPayment = {
        PickupAddress: _.get(res, "data.data.pickup_address", ""),
        DestinationAddress: _.get(res, "data.data.destination_address", ""),
        RoundTrip: Number(_.get(res, "data.data.round_trip", 0)),
        IsHaveBill: _.get(res, "data.data.is_have_bill", false),
        CustomerName: _.get(res, "data.data.customer_name", false),
        CustomerPhone: _.get(res, "data.data.customer_phone", ""),
        PickupTime: dayjs(form.getFieldValue("departDate")).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        TypeOfCar: _.get(res, "data.data.type_of_car", ""),
        TypeOfCarName: carSelected,
        PriceCustomer: _.get(res, "data.data.price_customer", 0),
        Amount: priceSelected || 0,
        VoucherCode: voucherCode,
        Note: "",
        Type: _.get(res, "data.data.type", null),
        Id: _.get(res, "data.data.id", null),
        AmountDiscount: voucherValue,
        Status: _.get(res, "data.data.status", ""),
        VatCompany: "",
        VatFullName: values?.VatFullName || "",
        VatMst: values?.VatMst || "",
        VatEmail: values?.VatEmail || "",
      };
      const resPayment = await $axios.post(
        "/payment/paymentRequired",
        bodyPayment
      );
      if (resPayment.data.data.paymentUrl) {
        window.location.href = resPayment.data.data.paymentUrl;
      } else {
        notification.error({
          status: "error",
          message: "Thất bại",
          description: "Đã có lỗi xảy ra! Vui lòng đặt chuyến khác",
        });
      }
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra! Vui lòng đặt chuyến khác",
      });
    }
  };

  const handleFinish = async (values) => {
    if (!addStart || !addEnd) {
      setErrors({
        ...errors,
        addStart: !addStart,
        addEnd: !addEnd,
      });
      return;
    }
    setLoading(true);
    try {
      //  Sân bay : schedule = 0,1,2
      // - Nội thành ra sân bay = 0
      // - Sân bay về nội thành = 2
      // - Nội thành ra sân bay nhưng chọn khứ hồi = 1
      // Đường dài : schedule = 3,4
      // - Đường dài không khứ hồi = 3
      // - Đường dài có khứ hồi = 4
      let schedule = 0;
      if (typeTrip === "road") {
        schedule = form.getFieldValue("roundtrip") ? 4 : 3;
      } else {
        schedule =
          disabledField === "addStart"
            ? 2
            : form.getFieldValue("roundtrip")
            ? 1
            : 0;
      }
      const pickupTime = `${dayjs(values.departDate).format(
        "YYYY-MM-DD HH:mm"
      )}`;
      const res = await $axios.$get(
        `/taxi/getPrice?surcharge=${addPrice}&pickupTime=${pickupTime}&schedule=${schedule}&addressStart=${addStart}&addressEnd=${addEnd}&vat=${
          values.VAT ? 1 : 0
        }&token=xevipnoibai`
      );
      setPrices(res.data.data);
      setCarSelected(typesCar[0].text);
      setPriceSelected(res.data.data[typesCar[0].value].price);
      setTimeout(() => {
        setIsModalHasPriceOpen(true);
      }, 0);
    } catch (error) {
      setIsModalNoPriceOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSwap = () => {
    setAddStart(addEnd);
    setAddEnd(addStart);
    if (typeTrip === "plane") {
      setDisabledField(disabledField === "addStart" ? "addEnd" : "addStart");
    }
  };

  const handleValuesFormChange = (changedValues, allValues) => {
    // console.log(allValues, "allValues");
  };

  const getAddPrice = async () => {
    setLoading(true);
    try {
      const res = await $axios.$get(`/payment/getAddPrice`);
      if (res.data.data.data.status === 1) {
        setAddPrice(_.get(res, "data.data.data.amount", 0));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getTypesCar = async () => {
    setLoading(true);
    try {
      const response = await $axios.$get(`/taxi/listTypeOfCar`);
      setTypesCar(
        response.data.data.filter((car) => {
          return ["Xe 4 chỗ", "Xe 5 chỗ", "Xe 7 chỗ", "Xe 16 chỗ"].includes(
            car.text
          );
        })
      );
    } catch (error) {
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalHasPriceOpen(false);
  };

  return (
    <div className="flex flex-col items-center mt-[0px] step1">
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        onValuesChange={handleValuesFormChange}
        initialValues={{
          departDate: dayjs(),
        }}
        onFinishFailed={(values) => {
          setErrors({
            ...errors,
            addStart: !addStart,
            addEnd: !addEnd,
          });
        }}
        className="form-search w-[92%]"
      >
        <div
          style={{
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
          className="rounded-[12px] bg-white p-4 flex flex-col items-center"
        >
          <div className="flex justify-between items-center w-full">
            <div className="text-[18px] text-[#0064D2] font-semibold">
              Đặt xe
            </div>
            <Form.Item name="type" className="mb-0">
              <Segmented
                onChange={(value) => {
                  setTypeTrip(value);
                  if (value === "plane") {
                    setDisabledField("addEnd");
                    setAddEnd("Sân bay Nội bài");
                  }
                }}
                options={[
                  {
                    label: (
                      <div className="w-[110px] flex items-center gap-2 p-2">
                        <FontAwesomeIcon icon={faPlane} color="#b91c1c" />
                        <div>Đi sân bay</div>
                      </div>
                    ),
                    value: "plane",
                  },
                  {
                    label: (
                      <div className="w-[110px] flex items-center gap-2 p-2">
                        <FontAwesomeIcon icon={faRoad} color="#b91c1c" />
                        <span>Đi đường dài</span>
                      </div>
                    ),
                    value: "road",
                  },
                ]}
                block
              />
            </Form.Item>
          </div>
          <Space.Compact
            className="w-full mt-5"
            direction="horizontal"
            block={true}
          >
            <div className="add-start-container">
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                options={{
                  componentRestrictions: { country: "VN" },
                  types: ["geocode", "establishment"],
                  radius: 50000,
                }}
                placeholder="Điểm đi"
                className={errors.addStart ? "border-error" : ""}
                language="vi"
                disabled={
                  typeTrip === "plane" && disabledField === "addStart"
                    ? true
                    : false
                }
                value={addStart}
                onChange={(e) => {
                  setAddStart(e.target.value);
                }}
                onPlaceSelected={(place) => {
                  setAddStart(place.formatted_address);
                  setErrors({
                    ...errors,
                    addStart: false,
                  });
                }}
              />
            </div>
            {/* <Button
              type="primary"
              size="large"
              style={{ backgroundColor: "#0064D2" }}
              onClick={() => {
                totalStopAddress < 2 && setTotalStopAddress((prev) => prev + 1);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} color="white" />
            </Button> */}
          </Space.Compact>
          {errors.addStart && (
            <div className="text-[14px] text-[#ff4d4f] self-start">
              Vui lòng nhập dữ liệu điểm đi!
            </div>
          )}
          {Array.from({ length: totalStopAddress }, (item, index) => (
            <Space.Compact
              className="w-full mt-3"
              direction="horizontal"
              block={true}
            >
              <div className="add-stop-container">
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  options={{
                    componentRestrictions: { country: "VN" },
                    types: ["geocode", "establishment"],
                    radius: 50000,
                  }}
                  language="vi"
                  placeholder="Điểm dừng"
                  onPlaceSelected={(place) => {
                    if (index === 1) {
                      setAddStop1(place.formatted_address);
                    } else {
                      setAddStop2(place.formatted_address);
                    }
                  }}
                />
              </div>
              <Button
                type="primary"
                size="large"
                className="bg-[#0064D2]"
                onClick={() => {
                  setTotalStopAddress((prev) => prev - 1);
                }}
              >
                {" "}
                <FontAwesomeIcon icon={faMinus} color="white" />
              </Button>
            </Space.Compact>
          ))}
          <Space.Compact
            className="w-full mt-5"
            direction="horizontal"
            block={true}
          >
            <div className="add-end-container">
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                options={{
                  componentRestrictions: { country: "VN" },
                  types: ["geocode", "establishment"],
                  radius: 50000,
                }}
                placeholder="Điểm đến"
                className={errors.addEnd ? "border-error" : ""}
                language="vi"
                value={addEnd}
                disabled={
                  typeTrip === "plane" && disabledField === "addEnd"
                    ? true
                    : false
                }
                onChange={(e) => {
                  setAddEnd(e.target.value);
                }}
                onPlaceSelected={(place) => {
                  setAddEnd(place.formatted_address);
                  setErrors({
                    ...errors,
                    addEnd: false,
                  });
                }}
              />
            </div>
          </Space.Compact>
          {errors.addEnd && (
            <div className="text-[14px] text-[#ff4d4f] self-start">
              Vui lòng nhập dữ liệu điểm đến!
            </div>
          )}
          <Row
            align={"middle"}
            justify={"space-between"}
            className="my-4 w-full"
          >
            <Col span={8}>
              <Form.Item
                name="roundtrip"
                className="mb-0"
                valuePropName="checked"
              >
                <Checkbox size="large">Khứ hồi</Checkbox>
              </Form.Item>
              {/* <label className="font-medium text-[#22313F]">Khứ hồi</label> */}
            </Col>
            <Col span={8}>
              <Form.Item name="VAT" className="mb-0" valuePropName="checked">
                <Checkbox size="large">VAT</Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                className="bg-[#0064D2] text-white"
                size="large"
                onClick={handleSwap}
              >
                <FontAwesomeIcon icon={faShuffle} color="white" />
                <div className="font-medium text-white text-[14px]">
                  Đảo chiều
                </div>
              </Button>
            </Col>
          </Row>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Vui lòng chọn ngày đi!",
              },
            ]}
            name={"departDate"}
            className="w-full mb-4"
          >
            <DatePicker
              className="w-full"
              size="large"
              showTime={{
                format: "HH",
                minuteStep: 60,
                defaultValue: dayjs("00:00", "HH"),
              }} // Cấu hình chỉ hiển thị giờ và phút
              placeholder="Chọn ngày"
              format="DD/MM/YYYY HH:mm"
              minDate={dayjs()}
              allowClear={false}
              locale={locale} // Thiết lập locale tiếng Việt
              suffixIcon={<img src={"/images/date.svg"} alt="" />}
            />
          </Form.Item>

          <Form.Item name="voucher" className="w-full mb-0">
            <Input.Search
              placeholder="Nhập mã giảm giá"
              enterButton={
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#0064D2",
                    borderColor: "#0064D2",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Áp dụng
                </Button>
              }
              allowClear
              onChange={(e) => {
                if (!e.target.value) {
                  setVoucherTxt("");
                  setVoucherValue(0);
                }
              }}
              onSearch={applyVoucher}
            />
          </Form.Item>
          {voucherTxt && (
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{ __html: voucherTxt }}
            />
          )}
          {/* <Divider variant="dashed" style={{ borderColor: "#0064D2" }} dashed /> */}
          {/* <div className="flex justify-between w-full items-center mb-5">
            <div className="flex gap-2">
              <img src={"/images/ic-money.svg"} alt="" className="w-[24px]" />
              <span className="text-[#22313F]">Cước phí đi</span>
            </div>
            {loadingPrice ? (
              <Spin indicator={<LoadingOutlined spin />} size="default" />
            ) : (
              <div className="text-[16px] text-[#0064D2] font-semibold">
                {totalPrice.toLocaleString("vi-VN")} ₫
              </div>
            )}
          </div> */}

          <Button
            type="primary"
            htmlType="sumit"
            loading={loading}
            style={{ height: 48 }}
            className="mb-4 w-full bg-[#0064D2] mt-[32px]"
          >
            <span className="text-[18px] font-bold">Kiểm Tra Giá</span>
            <FontAwesomeIcon icon={faArrowAltCircleRight} color="white" />
          </Button>
        </div>

        <div className="text-center my-[24px]">
          <span className="text-white  font-normal mr-4">
            Hotline hỗ trợ khách hàng{" "}
          </span>
          <a href="tel:0868320320" className="font-bold text-white text-[16px]">
            0876.100.800
          </a>
        </div>
      </Form>

      <Modal
        title="Xác nhận thông tin"
        open={isModalHasPriceOpen}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 0, padding: 0 }}
        width="100vw"
        bodyStyle={{
          padding: 0,
          margin: 0,
          overflow: "hidden",
        }}
        centered={!form.getFieldValue("VAT")}
      >
        <div className="text-[14px]">
          Giá cước đã bao gồm phí ra, vào sân bay Nội Bài, chưa bao gồm phí cầu
          đường khác.
        </div>
        <div className="flex justify-between flex-wrap my-3">
          {typesCar.map((car) => (
            <div
              key={car.value}
              className="flex gap-4 w-1/2 mb-3"
              onClick={() => {
                setCarSelected(car.text);
                setPriceSelected(
                  prices[car.value.toString()]?.price + addPrice - voucherValue
                );
              }}
            >
              <img
                className={
                  carSelected === car.text ? "car-selected" : "car-not-selected"
                }
                src={
                  car.text === "Xe 4 chỗ"
                    ? "/images/4seat.webp"
                    : car.text === "Xe 5 chỗ"
                    ? "/images/5seat.webp"
                    : car.text === "Xe 7 chỗ"
                    ? "/images/7seat.webp"
                    : "/images/16seat.webp"
                }
                alt=""
                width={50}
              />
              <div className="flex flex-col">
                <div>{car.text}</div>
                <div className="text-[16px] font-semibold text-[#0064D2]">
                  {(
                    prices[car.value.toString()]?.price +
                    addPrice -
                    voucherValue
                  ).toLocaleString("vi-VN")}
                  đ
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center text-yellow-500">
          Đặt xe sớm để được ưu đãi tốt nhất!
        </div>
        <Divider
          variant="dashed"
          style={{ borderColor: "#0064D2" }}
          dashed
          className="my-3 py-0"
        />
        <Form
          form={formContact}
          onFinish={handleFinishContact}
          layout="vertical"
          onFinishFailed={(values) => {}}
          className="w-full"
        >
          <div className="text-[#0064D2] self-start text-[16px] my-2 font-semibold">
            Thông tin của bạn
          </div>
          <div className="w-full">
            <Form.Item
              name="fullName"
              label="Họ và tên"
              rules={[
                {
                  required: true,
                  message: "Họ và tên không được để trống",
                },
              ]}
              className="mb-3 w-full"
            >
              <Input
                size="large"
                prefix={<FontAwesomeIcon icon={faUser} color="#0064D2" />}
                placeholder="Họ và tên"
              />
            </Form.Item>
          </div>
          <Form.Item
            name="phone"
            label="Số điện thoại"
            rules={[
              {
                required: true,
                message: "Số điện thoại không được để trống",
              },
              {
                pattern: /^(03|05|07|08|09)\d{8}$|^\+84\d{9}$/,
                message: "Số điện thoại không hợp lệ",
              },
            ]}
            className="mb-5 w-full"
          >
            <Input
              size="large"
              prefix={<FontAwesomeIcon icon={faPhone} color="#0064D2" />}
              placeholder="Nhập số điện thoại..."
            />
          </Form.Item>
          {form.getFieldValue("VAT") && (
            <>
              <div className="text-[#0064D2] self-start text-[16px] my-2 font-semibold">
                Thông tin xuất hoá đơn
              </div>
              <Form.Item
                name="VatMst"
                className="mb-3"
                label={<div className="text-[14px]">Mã số thuế (VAT)</div>}
                rules={[
                  {
                    required: true,
                    message: "Mã số thuế không được để trống",
                  },
                ]}
              >
                <Input size="large" placeholder="Mã số thuế" />
              </Form.Item>
              <Form.Item
                label={<div className="text-[14px]">Người nhận hoá đơn</div>}
                name="VatFullName"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Người nhận hoá đơn không được để trống",
                  },
                ]}
              >
                <Input size="large" placeholder="Nhập người nhận hoá đơn" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="VatEmail"
                className="mb-3"
                rules={[
                  {
                    required: true,
                    message: "Email không được để trống",
                  },
                  {
                    type: "email",
                    message: "Vui lòng nhập email đúng định dạng",
                  },
                ]}
              >
                <Input size="large" placeholder="Nhập Email" />
              </Form.Item>
            </>
          )}
          <div className="flex justify-between gap-4 py-4">
            <Button
              style={{ height: 48 }}
              onClick={handleCancel}
              className="bg-red-700 hover:bg-red-700 focus:bg-red-700"
            >
              <span className="text-[18px] font-bold text-white">Huỷ</span>
            </Button>
            <Button
              type="primary"
              htmlType="sumit"
              style={{ height: 48 }}
              className=" bg-[#0064D2] flex-1"
              loading={loading}
            >
              <span className="text-[18px] font-bold">Xác nhận đặt chuyến</span>
            </Button>
          </div>
          <div className="text-red-500 mt-0">
            Quý khách sẽ tiến hành thanh toán sau khi đặt chuyến!
          </div>
        </Form>
      </Modal>
      <Modal
        title="Thông báo"
        open={isModalNoPriceOpen}
        width="100%"
        onCancel={() => setIsModalNoPriceOpen(false)}
        footer={null}
      >
        <div className="text-[14px] font-semibold text-[16px]">
          Để được hỗ trợ nhanh nhất, quý khách vui lòng bấm gọi hotline.
          <br />
          Cảm ơn quý khách đã tin tưởng và sử dụng dịch vụ của Xe VIP Nội Bài!
        </div>
        <div className="w-full text-center my-4">
          <Button
            type="primary"
            htmlType="sumit"
            style={{ height: 48 }}
            className=" bg-[#0064D2] flex-1"
          >
            <a className="text-[18px] font-bold" href="tel:1900888697">
              Hotline: 0876.100.800
            </a>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Booking;
