import {
  Button,
  Form,
  Input,
  Row,
  Pagination,
  notification,
  DatePicker,
} from "antd";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/vi_VN";
import $axios from "../../services/axios";
import _ from "lodash";
import moment from "moment";
import dayjs from "dayjs";
import trans from "../../data-test/test.json";

const History = ({ setStep, setLoading }) => {
  const [form] = Form.useForm();
  const [listHistory, setListHistory] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rangePresets = [
    {
      label: "7 ngày qua",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "14 ngày qua",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "30 ngày qua",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "90 ngày qua",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  useEffect(() => {
    getListHistory(currentPage, _.get(rangePresets, "[0].value", []));
  }, []);

  const getListHistory = async (page, dateRange) => {
    const startTime = dayjs(dateRange[0]).format("DD/MM/YYYY") + " 00:00:00";
    const endTime = dayjs(dateRange[1]).format("DD/MM/YYYY") + " 00:00:00";
    setLoading(true);
    try {
      const res = await $axios.$get(
        `/transactions/list?startDate=${startTime}&endDate=${endTime}&page=${page}&pageSize=10`
      );
      setListHistory(res.data.data.data);
      setTotal(res.data.data.totalRecordCount);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    try {
      await getListHistory(currentPage, values.dateRange);
    } catch (error) {
      notification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col gap-2 items-center w-[92%]">
        <h2 className="text-white text-[16px] font-semibold mb-4">
          Lịch sử đặt vé
        </h2>
        <div className="w-full px-5 py-6 rounded-[12px] bg-white">
          <Form
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              dateRange: rangePresets[0].value, // Giá trị mặc định cho `dateRange`
            }}
            className="flex items-start gap-2 m-0"
          >
            {/* <Form.Item
              name={"keyword"}
              rules={[{ required: true, message: "" }]}
              className="m-0 flex-1"
            >
              <Input className="h-10" placeholder="Nhập từ khoá..." />
            </Form.Item> */}
            <Form.Item
              name={"dateRange"}
              rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
              className="m-0 flex-1"
            >
              <DatePicker.RangePicker
                popupClassName="ant-picker-no-left"
                presets={rangePresets}
                format="DD/MM/YYYY"
                className="h-10"
                locale={locale}
                suffixIcon={<img src={"/images/date.svg"} alt="" />}
              />
            </Form.Item>

            <Form.Item className="m-0">
              <Button
                type="primary"
                htmlType="submit"
                className="bg-[#0064D2] h-10 font-bold text-white"
              >
                Tìm kiếm
              </Button>
            </Form.Item>
          </Form>
          {/* Hotline */}
        </div>
        <div className="w-full">
          <div className="text-center mt-2">
            <span className="text-white font-normal">
              Hotline hỗ trợ khách hàng{" "}
            </span>
            <a
              href="tel:0868320320"
              className="font-bold text-white text-[16px]"
            >
              0876.100.800
            </a>
          </div>
          {total > 0 && <p className="font-bold text-white my-4">Vé taxi</p>}
        </div>
        {/* {detail?.ErrorCode === "404" && (
            <h2 className="w-full text-primary font-semibold text-[18px] text-center absolute top-[480px] left-1/2 transform -translate-x-1/2 p-4">
              Không tìm thấy lịch sử đặt vé, vui lòng tìm kiếm lại
            </h2>
          )} */}
        {listHistory.length > 0 &&
          listHistory.map((item, index) => (
            <div
              key={item.id}
              className="mt-2 w-full p-4 bg-white rounded-[12px] mb-4 "
              style={{
                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div className="items-start flex gap-1 flex-col">
                <p className="text-[12px]">
                  Mã đơn hàng :{" "}
                  <span className="text-orange-600 text-[10px] font-semibold">
                    {item.orderCode}
                  </span>
                </p>
                <p className="text-[12px]">
                  Điểm đi :{" "}
                  <span className="text-primary text-[12px] font-semibold">
                    {item.pickupAddress}
                  </span>
                </p>
                <p className="text-[12px]">
                  Điểm đến :{" "}
                  <span className="text-primary text-[12px] font-semibold">
                    {item.destinationAddress}
                  </span>
                </p>
              </div>
              <hr className="my-2" />
              <Row className="justify-between items-center">
                <div className="flex gap-2 items-center w-1/2">
                  <div className="flex flex-col gap-1">
                    <p className="text-[10px]">
                      Họ và tên :{" "}
                      <span className="text-primary text-[10px] font-semibold">
                        {item.customerName}
                      </span>
                    </p>
                    <p className="text-[10px]">
                      Số điện thoại :{" "}
                      <span className="text-primary text-[10px] font-semibold">
                        {item.customerPhone}
                      </span>
                    </p>
                    <div
                      className={`text-white ${
                        item.paymentStatus === 1 ? "bg-green-600" : "bg-red-600"
                      } rounded-[20px] px-4 py-1 w-fit text-[10px]`}
                    >
                      {item.paymentStatus === 1
                        ? "Đã thanh toán"
                        : "Chưa thanh toán"}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1 w-1/2">
                  <p className="text-[10px]">
                    Thời gian:{" "}
                    <span className="font-bold text-primary text-[10px] ">
                      {moment(item.pickupTime).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </p>
                  <p className="text-[10px]">
                    Khứ hồi:{" "}
                    <span className="font-bold text-primary text-[10px] ">
                      {item.roundTrip === 1 ? "Có" : "Không"}
                    </span>
                  </p>

                  <p className="text-[10px]">
                    Loại xe :{" "}
                    <span className="text-primary text-[10px] font-semibold">
                      {item.typeOfCarName}
                    </span>
                  </p>
                  <p className="text-[10px]">
                    Tổng tiền :{" "}
                    <span className="text-primary text-[10px] font-semibold">
                      {item.amount}
                    </span>
                  </p>
                </div>
              </Row>
            </div>
          ))}

        {total > 0 && (
          <Pagination
            current={currentPage}
            className="mt-2"
            total={total}
            onChange={(page) => {
              setCurrentPage(page);
              getListHistory(page, form.getFieldValue("dateRange"));
            }}
            pageSize={10}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        )}

        <div className="w-full mt-4 px-4">
          <div className="flex justify-between">
            <Button
              onClick={() => {
                setStep("booking");
              }}
              className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#0064D2] border-[#0064D2] text-[15px]"
            >
              <span className="font-semibold">Trang chủ</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
