import React, { useEffect, createContext, useState } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "./pages/Home";
import { Modal } from "antd";
import $axios from "./services/axios";

const ReachableContext = createContext(null);
const config = {
  title: "Token không hợp lệ!",
  maskClosable: false,
  footer: null,
  content: (
    <>
      <ReachableContext.Consumer>
        {(name) => `Có lỗi xảy ra vui lòng trở về trang chủ`}
      </ReachableContext.Consumer>
    </>
  ),
};

const App = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [token, setToken] = useState(localStorage.getItem("taxiToken"));
  const history = useHistory();
  const location = useLocation();

  const decodeToken = async () => {
    // Lấy token từ URL
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("verifyToken") || localStorage.getItem("taxiToken")) {
        const verifyToken = queryParams.get("verifyToken");
        if (verifyToken) {
          const res = await $axios.$post("/login", {
            VerifyToken: verifyToken,
          });
          localStorage.setItem("taxiToken", res.data.data.token);
          queryParams.delete("verifyToken");
          setToken(res.data.data.token);
          history.replace({
            pathname: location.pathname,
            search: queryParams.toString(),
          });
        }
      } else {
        modal.error(config);
      }
    } catch (error) {}
  };

  useEffect(() => {
    decodeToken();
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from="/" to={"/booking-taxi"} />
        <Route
          exact
          path={"/booking-taxi"}
          render={(props) => <Home {...props} token={token} />}
        />
        <Redirect to={"/booking-taxi"} />
      </Switch>
      {contextHolder}
    </>
  );
};
export default App;
