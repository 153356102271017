import React, { useState, useEffect } from "react";
import _ from "lodash";
import Booking from "../components/Steps/Booking";
import { Spin, Button } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import History from "../components/Steps/History";
import { LeftOutlined } from "@ant-design/icons";
import PaymentResult from "../components/Steps/PaymentResult";

const Home = ({ token }) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(null);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    hanleProcessPaymentReturn();
  }, [location, history]);

  const hanleProcessPaymentReturn = async () => {
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
    const payment_type = queryParams.get("payment_type");
    const reference_number = queryParams.get("reference_number");
    const status = queryParams.get("status");
    const trans_ref_no = queryParams.get("trans_ref_no");
    const website_id = queryParams.get("website_id");
    const signature = queryParams.get("signature");

    if (
      amount &&
      payment_type &&
      reference_number &&
      status &&
      trans_ref_no &&
      website_id &&
      signature
    ) {
      setStep("payment-result");
    } else if (!step && token) {
      setStep("booking");
    }
  };

  return (
    <Spin tip="Tải dữ liệu" size="large" spinning={loading}>
      <div
        className="bg-[#f6f6f6] min-h-screen"
        style={{
          backgroundImage: `url(/images/bg-blue.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="w-full h-[300px] absolute"
          style={{
            backgroundImage: `url(/images/bg-form.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        ></div>
        <div className="flex w-full z-10 justify-between items-center">
          {step === "booking" ? (
            <div className="w-[67px] h-8"></div>
          ) : (
            <div className="w-[67px] ml-4">
              <Button
                className="text-[14px]"
                icon={<LeftOutlined color="#1677ff" />}
                onClick={() => {
                  setStep("booking");
                }}
              />
            </div>
          )}
          <div className="text-center">
            <a href="/booking-taxi">
              <img src={"/images/ipass-logo.png"} alt="" className="w-[80px]" />
            </a>
          </div>
          {step === "booking" ? (
            <div className="p-5 cursor-pointer inline-block z-10">
              <img
                onClick={() => {
                  setStep("history");
                }}
                className="w-[32px] p-1 h-[32px] bg-white rounded-full"
                src={"/images/history.svg"}
                alt=""
              />
            </div>
          ) : (
            <div className="w-[67px] h-8"></div>
          )}
        </div>
        {step === "booking" && (
          <Booking setLoading={setLoading} loading={loading} />
        )}
        {step === "history" && (
          <History
            setStep={setStep}
            setLoading={setLoading}
            loading={loading}
          />
        )}
        {step === "payment-result" && (
          <PaymentResult setLoading={setLoading} loading={loading} />
        )}
        {/* <div className="flex bg-white h-[81px] w-full px-[24px] py-3 fixed bottom-0 justify-between items-center">
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
          <a
            href="/ve-may-bay"
            className="text-[#22313F] text-[14px] font-medium"
          >
            Vé máy bay
          </a>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">
            Khách sạn
          </span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">
            Vé tàu
          </span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">
            Vé xe
          </span>
        </div>
      </div> */}
      </div>
    </Spin>
  );
};

export default Home;
